<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>

                  <CAlert color="danger" v-if="loggedInError == false">
                    Oops! invalid credentials.
                  </CAlert>
                  <CAlert color="success" v-if="loggedInError == true">
                    Login Sucessfull!
                  </CAlert>

                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                    v-model="username"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        @click="login()"
                        :disabled="frmDisable"
                        ><span v-if="!isLoading">Login</span>

                        <semipolar-spinner
                          :animation-duration="2000"
                          :size="30"
                          color="#ffffff"
                          v-if="isLoading"
                          >Login</semipolar-spinner
                        >
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0"
                        >Forgot password?</CButton
                      >
                      <CButton color="link" class="d-lg-none"
                        >Register now!</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              text-color="white"
              class="text-center py-5 d-md-down-none"
              style="
                width: 44%;
                background: url('/img/edu.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 79% 2%;
              "
              body-wrapper
            >
              <CCardBody>
                <!-- <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton> -->
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { SemipolarSpinner } from "epic-spinners";
export default {
  name: "Login",
  components: {
    SemipolarSpinner,
  },
  data() {
    return {
      username: "fernando@apachedigital.io",
      password: "Social.236",
      loggedInError: null,
      isLoading: false,
    };
  },
  computed: {
    frmDisable() {
      if (this.username && this.password) {
        return false;
      }
      return true;
    },
  },
  methods: {
    login() {
      this.loggedInError = null;
      this.isLoading = true;
      if (this.username && this.password) {
        // fernando@apachedigital.io
        // Social.236
        this.$store
          .dispatch("a_login", {
            user: this.username,
            password: this.password,
          })
          .then((data) => {
            const status = this.$store.getters.g_status_auth;
            const { is_admin = false } = this.$store.getters.g_authpayload;
            // console.log(this.$store.getters.g_authpayload);
            this.isLoading = false;
            if (status === "error") {
              this.loggedInError = false;
            } else {
              this.$store.dispatch("a_usergroup").then((data) => {
                const {
                  in_group_21 = null,
                  in_group_23 = null,
                } = this.$store.getters.g_userGroup[0];
                let is_admin = null;
                if (in_group_21) {
                  is_admin = false;
                } else if (in_group_23) {
                  is_admin = true;
                } else {
                  this.$errorToast("Unable to find the user group...");
                  return;
                }
                let role = is_admin ? "admin" : "callcenter";
                Cookies.set("edu_role", role);
                Cookies.set("edu_islogged", "true");
                Cookies.set("edu_lasttab", role);
                this.$store.dispatch("a_LoggedIn", {
                  payload: this.$store.getters.g_authpayload,
                  role,
                });
                this.$successToast("Login Sucessfull...");
                if (role === "callcenter") {
                  this.$router.replace({
                    name: "CallCenter",
                  });
                } else {
                  this.$router.replace({
                    name: "Admin",
                  });
                }
              });
            }
          });
        return;
      }
    },
  },
};
</script>
<style scoped>
/* .d-right-banner {
  background: url("@/assets/edu.jpg");
} */
</style>
